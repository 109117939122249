const theme = {
  /* Font Families */
  bodyFont: `"Helvetica Neue", 
    "Arial", 
    system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ Segoe UI,
    /* Android 4+ */ Roboto,
    /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell,
    /* KDE Plasma 5+ */ Noto Sans,
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji"`,

  headingFont: `"HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", 
    "Arial", 
    system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ Segoe UI,
    /* Android 4+ */ Roboto,
    /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell,
    /* KDE Plasma 5+ */ Noto Sans,
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji"`,

  /* Type scale (apply to local variables rather than using directly) */
  f1: `.75rem`,
  f2: `.875rem`,
  f3: `1rem`,
  f4: `1.125rem`,
  f5: `1.25rem`,
  f6: `1.5rem`,
  f7: `1.875rem`,
  f8: `2.25rem`,
  f9: `3rem`,
  f10: `3.75rem`,
  f11: `4.5rem`,

  /* Font Weights */
  fw1: 400,
  fw2: 700,

  /* Line Heights */
  lh1: 1.2,
  lh2: 1.5,
  lh3: 1.7,

  /* Letter Spacings */
  ls1: `-.05em`,
  ls2: `.05em`,
  ls3: `.1em`,
  ls4: `.25em`,

  /* Space and Size Scale (margin, padding, width, height, border width) */
  s1: `.25rem`,
  s2: `.5rem`,
  s3: `.75rem`,
  s4: `1rem`,
  s5: `1.5rem`,
  s6: `2rem`,
  s7: `3rem`,
  s8: `4rem`,
  s9: `6rem`,
  s10: `8rem`,
  s11: `12rem`,
  s12: `16rem`,
  s13: `24rem`,
  s14: `32rem`,
  s15: `48rem`,
  s16: `64rem`,
  s17: `96rem`,

  /* Max-widths */
  measure1: `45ch`,
  measure2: `55ch`,
  measure3: `66ch`,
  measure4: `75ch`,

  /* Border width */
  bw0: `0`,
  bw1: `.0625rem`,
  bw2: `.125rem`,
  bw3: `.25rem`,
  bw4: `.5rem`,
  bw5: `1rem`,
  bw6: `2rem`,

  /* Border radius */
  r0: `0`,
  r1: `.125rem`,
  r2: `.25rem`,
  r3: `.375rem`,
  r4: `.5rem`,
  r5: `.75rem`,
  r6: `1rem`,
  r100: `100%`,

  /* Shadows */
  shadow1: `0 1px 3px hsla(0, 0%, 0%, .2)`,
  shadow2: `0 4px 6px hsla(0, 0%, 0%, .2)`,
  shadow3: `0 5px 15px hsla(0, 0%, 0%, .2)`,
  shadow4: `0 10px 24px hsla(0, 0%, 0%, .2)`,
  shadow5: `0 15px 35px hsla(0, 0%, 0%, .2)`,
  shadow6: `0 10px 50px hsla(0, 0%, 0%, 0.33)`,
  inset: `inset 0 2px 2px hsla(0, 0%, 0%, .1)`,

  /* Colors */
  black: `hsla(0, 0%, 0%, .85)`,
  orange: `#DD8833` /* for underlines and orange areas */,
  lightOrange: `#FDF8EF` /* for box fields */
}

///////////////////////////////////////////////////////////////////////////////////

export default theme
