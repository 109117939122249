function PortfolioHero({ production }) {
  const items = usePortfolioData()
  const currentProductionIndex = items.findIndex(
    item => item.node.slug === production.slug
  )

  let prevLink, nextLink
  if (currentProductionIndex > 0) {
    nextLink = items[currentProductionIndex - 1].node.slug
  }

  if (currentProductionIndex < items.length - 1) {
    prevLink = items[currentProductionIndex + 1].node.slug
  }

  function scrollToSection(e, id) {
    e.preventDefault()
    scrollToId(id)
  }

  return (
    <Header id="top">
      <Link href="/">
        <Logo aria-hidden="true" />
        <SrText>Navigate to Home page</SrText>
      </Link>

      <LeftNav aria-label="Navigate to other portfolio items">
        <PortfolioLink href={`/#portfolio`}>Portfolio</PortfolioLink>

        {prevLink && (
          <PortfolioLink href={`/${prevLink}`}>
            Previous<SrText> production</SrText>
          </PortfolioLink>
        )}

        {nextLink && (
          <PortfolioLink href={`/${nextLink}`}>
            Next<SrText> production</SrText>
          </PortfolioLink>
        )}
      </LeftNav>

      <Details>
        <Title
          lang={production.title.lang}
          dangerouslySetInnerHTML={{ __html: production.title.text }}
        />

      {production.company && (
        <CircleWrapper>
          <Circle />
          <Company dangerouslySetInnerHTML={{ __html: production.company }} />
        </CircleWrapper>
        )}
      {production.venue && (
        <CircleWrapper>
          <Circle />
          <Venue dangerouslySetInnerHTML={{ __html: production.venue }} />
        </CircleWrapper>
        )}
      {production.dates && (
        <CircleWrapper>
          <Circle />
          <Dates dangerouslySetInnerHTML={{ __html: production.dates }} />
        </CircleWrapper>
        )}
      </Details>

      <PhotoWrapper>
        <Photo
          fluid={{
            ...production.featuredImage.file.childImageSharp.fluid,
            aspectRatio: 8 / 5
          }}
          alt={`A scene from Alaina's production of ${production.title.text.replace(
            `&nbsp;`,
            ` `
          )}`}
          critical={true}
        />
        <PhotoSquare />
      </PhotoWrapper>

      <ScrollDown
        href="#main-content"
        onClick={e => scrollToSection(e, `#main-content`)}
      >
        <Arrow aria-hidden="true" />
        <SrText>Scroll to production details</SrText>
      </ScrollDown>

      <RightNav>
        <ul>
          <Item>
            <NavLink href="/">Home</NavLink>
          </Item>
          <Item>
            <NavLink href="#contact" onClick={e => scrollToSection(e, `#contact`)}>
              Contact
            </NavLink>
          </Item>
        </ul>
      </RightNav>
    </Header>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  /* background-color: yellow; */
  position: relative;
  ${container}
  max-width: 72em;
  padding: ${p => p.theme.s4};

  ${media.md`
    display: grid;
  `}
`

const Logo = styled(LogoSVG)`
  width: 70%;
  max-width: ${p => p.theme.s12};

  ${media.md`
    margin-top: ${p => p.theme.s6};
    max-width: ${p => p.theme.s11};
  `}
`

const LeftNav = styled.nav`
  ${media.md`
    position: absolute;
    top: 48%;
    left: 2rem;
    transform-origin: 0% 0%;
    transform: rotate(-90deg);
    text-align: center;
  `}

  ${media.xl`
    left: 4rem;
  `}
`

const PortfolioLink = styled(Link)`
  ${underlineThatGrowsOnHover};
  margin-top: ${p => p.theme.s5};
  margin-right: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  ${media.md`
    margin-top: 0;
    margin-right: 0;
    margin-left: ${p => p.theme.s6};
  `}
`

const Details = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${p => p.theme.s16};

  ${media.xl`
    padding-left: ${p => p.theme.s8};
    padding-right: ${p => p.theme.s8};
  `}
`

const Title = styled.h1`
  margin-top: ${p => p.theme.s6};
  padding-bottom: ${p => p.theme.s2};
  line-height: 1.1;
  font-family: ${p => p.theme.headingFont};
  font-size: 2.4rem;
  text-transform: uppercase;
  color: ${p => p.theme.orange};

  @media only screen and (min-width: 375px) {
    font-size: 2.6rem;
  }

  ${media.sm`
    font-size: ${p => p.theme.f9};
  `}

  ${media.md`
    margin-top: ${p => p.theme.s4};
  `}
`

const CircleWrapper = styled.div`
  display: flex;
  margin-top: ${p => p.theme.s2};
  max-width: 33rem;
`

const Circle = styled(CircleSVG)`
  flex: none;
  margin-top: ${p => p.theme.s2};
  margin-right: ${p => p.theme.s2};
  height: 0.6em;
  width: 0.6em;
  color: ${p => p.theme.orange};
`

const Company = styled.p`
  line-height: 1.3;
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
`

const Venue = styled.p`
  line-height: 1.3;
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
`

const Dates = styled.p`
  line-height: 1.3;
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
`

const PhotoWrapper = styled.div`
  position: relative;
  margin-top: ${p => p.theme.s5};

  ${media.md`
    order: -2;
    margin-top: ${p => p.theme.s6};
    margin-left: auto;
    margin-right: 7rem;
    width: 60%;
  `}
`

const PhotoSquare = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: -1;
  background-color: ${p => p.theme.lightOrange};
  width: 90%;
  height: 100%;
`

const Photo = styled(Image)`
  margin-right: 5%;
  box-shadow: ${p => p.theme.shadow5};
`

const ScrollDown = styled(Link)`
  display: block;
  margin-top: ${p => p.theme.s6};

  ${media.md`
    margin-top: ${p => p.theme.s7};
  `}
`

const Arrow = styled(ArrowSVG)`
  display: block;
  margin-bottom: ${p => p.theme.s1};
  transform: rotate(180deg);
  min-width: 100%;
  height: 1.6rem;
  transition: transform 0.2s ease-in-out;

  ${ScrollDown}:hover & {
    transform: translateY(0.35em) rotate(180deg);
  }
`

const RightNav = styled.nav`
  display: none;

  ${media.md`
    display: block;
    position: absolute;
    top: 10%;
    right: 2rem;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
    text-align: center;
  `}
 
  ${media.lg`
    top: 12%;
  `}

  ${media.xl`
    right: 4rem;
  `}
`

const Item = styled.li`
  text-align: center;

  ${media.md`
    display: inline-flex;
    margin: 0 ${p => p.theme.s4};
  `}
`

const NavLink = styled(Link)`
  ${underlineThatGrowsOnHover}

  margin-top: ${p => p.theme.s1};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  &:after {
    left: 50%;
    transform: translateX(-50%)
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Link, SrText } from './elements'
import scrollToId from '../logic/scrollToId'
import usePortfolioData from '../data/usePortfolioData'
import { ReactComponent as ArrowSVG } from '../svg/arrow.svg'
import { ReactComponent as CircleSVG } from '../svg/circle-solid.svg'
import { ReactComponent as LogoSVG } from '../svg/logo.svg'
import { container, media, underlineThatGrowsOnHover } from '../styles'

export default PortfolioHero
