export const container = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.s4};
  padding-right: ${p => p.theme.s4};
  max-width: ${p => p.theme.s16};

  ${media.md`
    padding-left: ${p => p.theme.s6};
    padding-right: ${p => p.theme.s6};
  `}

  ${media.xl`
    padding-left: ${p => p.theme.s8};
    padding-right: ${p => p.theme.s8};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

import { css } from 'styled-components'

import { media } from './media'
