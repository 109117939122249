function Base({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Metadata preconnect={[`https://unpkg.com`]} />
        <Reset />
        {children}
      </>
    </ThemeProvider>
  )
}

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import { ThemeProvider } from 'styled-components'

import Metadata from './Metadata'
import { theme, Reset } from '../styles'

export default Base
