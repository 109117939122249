function PortfolioDetails({ production }) {
  return (
    <Section>
      <h2>
        <SrText>Production details</SrText>
      </h2>

      <Heading>Description</Heading>
      <Text dangerouslySetInnerHTML={{ __html: production.description }} />

      {production.creativeTeam && (
        <>
          <Heading>Creative Team</Heading>
          <List>
            {production.creativeTeam.map(member => (
              <Item key={member.name}>
                {member.name}
                {member.role && (
                  <>
                    {`, `}
                    <Orange dangerouslySetInnerHTML={{ __html: member.role }} />
                  </>
                )}
              </Item>
            ))}
          </List>
        </>
      )}

    {production.performers && (
      <>
      <Heading>Performers</Heading>
      <List>
        {production.performers.map(performer => (
          <Item key={performer.name}>
            {performer.name}
            {performer.role && (
              <span>
                , <Orange>{performer.role}</Orange>
              </span>
            )}
          </Item>
        ))}
      </List>
      </>
      )}
    
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s6};
  padding-bottom: ${p => p.theme.s6};

  ${media.md`
    padding-bottom: ${p => p.theme.s7};
  `}
`

const Heading = styled.h3`
  padding-top: ${p => p.theme.s7};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f7};
  text-transform: uppercase;
`

const Text = styled.p`
  margin-top: ${p => p.theme.s5};
  ${copy}

  ${media.sm`
    margin-left: ${p => p.theme.s4};
  `}
`

const List = styled.ul`
  margin-top: ${p => p.theme.s5};

  ${media.sm`
    margin-left: ${p => p.theme.s4};
  `}
`

const Item = styled.li`
  margin-top: ${p => p.theme.s1};
  font-weight: ${p => p.theme.fw2};
`

const Orange = styled.span`
  color: ${p => p.theme.orange};
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { SrText } from './elements'
import { container, copy, media } from '../styles'

export default PortfolioDetails
