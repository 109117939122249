function PortfolioMedia({ media, titleForAltText }) {
  const videos = useMemo(() => media.filter(item => item.category === `video`), [
    media
  ])

  const images = useMemo(() => media.filter(item => item.category === `image`), [
    media
  ])

  const mediaMachine = filterAndLimitMachine.withContext({
    category: images.length > 0 ? `image` : videos.length > 0 ? `video` : `all`,
    limit: 3,
    limitsByScreen: { md: 6, sm: 4, xs: 3 }
  })
  const [state, send] = useMachine(mediaMachine)
  useRecalculateLimit(state, send)

  const mediaInCategory = useMemo(() => filterItemsByCategory(state, media), [
    state,
    media
  ])

  const visibleMedia = useMemo(() => limitItems(state, mediaInCategory), [
    state,
    mediaInCategory
  ])

  const limited = useMemo(() => state.context.limit < mediaInCategory.length, [
    state.context.limit,
    mediaInCategory.length
  ])

  return (
    <Section>
      <h2>
        <SrText>Production Videos &amp; Photos</SrText>
      </h2>

      <Filters
        category={state.context.category}
        videos={videos.length > 0}
        images={images.length > 0}
        send={send}
      />

      <div id="media">
        {state.context.category === `image` && (
          <Images
            visibleImages={visibleMedia}
            allImages={mediaInCategory}
            limited={limited}
            previousLimit={state.context.previousLimit}
            titleForAltText={titleForAltText}
          />
        )}

        {state.context.category === `video` && (
          <Videos
            visibleVideos={visibleMedia}
            limited={limited}
            previousLimit={state.context.previousLimit}
          />
        )}
      </div>

      {limited && (
        <ViewAll onClick={() => send('VIEW_ALL')} aria-expanded={false}>
          View all {state.context.category}s
        </ViewAll>
      )}
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s6};
  padding-bottom: ${p => p.theme.s7};

  ${media.md`
    padding-bottom: ${p => p.theme.s8};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

function Filters({ category, videos, images, send }) {
  return (
    <Toolbar role="toolbar" aria-label="filter options" aria-controls="media">
      {images && <Filter value="image" category={category} send={send} />}
      {videos && <Filter value="video" category={category} send={send} />}
    </Toolbar>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Toolbar = styled.div`
  padding-top: ${p => p.theme.s4};
`

///////////////////////////////////////////////////////////////////////////////////

function Filter({ value, category, send }) {
  return (
    <Button
      value={value}
      onClick={() => send({ type: 'CHANGE_CATEGORY', category: value })}
      aria-pressed={category === value}
    >
      {value}s
    </Button>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Button = styled.button`
  ${underlineThatGrowsOnHover}

  margin-right: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f6};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;

  &[aria-pressed='true'] {
    &:after {
      height: 0.75em;
    }
  }
`

const ViewAll = styled.button`
   ${underlineThatGrowsOnHover}
  margin-top: ${p => p.theme.s6};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f4};
  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

function Images({
  visibleImages,
  allImages,
  limited,
  previousLimit,
  titleForAltText
}) {
  const imagesMachine = lightboxMachine.withContext({
    ...lightboxMachine.context,
    imageCount: allImages.length
  })
  const [state, send] = useMachine(imagesMachine)

  const { currentIndex, nextIndex, prevIndex } = state.context

  return (
    <>
      <List>
        {visibleImages.map((item, i) => (
          <LiWithCSSAnimation
            key={item.file.childImageSharp.thumbnail.src}
            style={{
              animationDelay: limited
                ? `calc(${i * 100}ms)`
                : `calc(${previousLimit * -100}ms + ${i * 100}ms)`
            }}
          >
            <Thumbnail
              image={item}
              lightboxIndex={i}
              isLightboxOpen={state.value === `open`}
              send={send}
              titleForAltText={titleForAltText}
            />
          </LiWithCSSAnimation>
        ))}
      </List>

      {state.value === `open` && (
        <Lightbox
          mainSrc={allImages[currentIndex].file.childImageSharp.lightbox.src}
          nextSrc={allImages[nextIndex].file.childImageSharp.lightbox.src}
          prevSrc={allImages[prevIndex].file.childImageSharp.lightbox.src}
          imageCaption={allImages[currentIndex].caption}
          onCloseRequest={() => send('CLOSE')}
          onMovePrevRequest={() => send({ type: 'SET_INDEX', index: prevIndex })}
          onMoveNextRequest={() => send({ type: 'SET_INDEX', index: nextIndex })}
          enableZoom={false}
          animationOnKeyInput={true}
        />
      )}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-column-gap: ${p => p.theme.s6};
`

const LiWithCSSAnimation = styled.li`
  visibility: hidden;
  transform-origin: bottom;
  margin-top: ${p => p.theme.s6};
  animation: ${enter} 1s cubic-bezier(0.5, 0, 0, 1) forwards;
`

///////////////////////////////////////////////////////////////////////////////////

function Thumbnail({ image, titleForAltText, lightboxIndex, isLightboxOpen, send }) {
  return (
    <Wrapper>
      <Photo
        fluid={{
          ...image.file.childImageSharp.thumbnail,
          aspectRatio: 1 / 1
        }}
        alt={`A scene from Alaina's production of ${titleForAltText}`}
      />
      <OpenLightbox
        value={lightboxIndex}
        onClick={() => send({ type: 'OPEN', index: lightboxIndex })}
        aria-expanded={isLightboxOpen}
      >
        View Image
      </OpenLightbox>
    </Wrapper>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Wrapper = styled.div`
  position: relative;
`

const Photo = styled(Image)`
  box-shadow: ${p => p.theme.shadow4};
`

const OpenLightbox = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  background-color: hsla(0, 0%, 0%, 0%);
  width: 100%;
  height: 100%;
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f7};
  text-transform: uppercase;
  color: hsla(0, 100%, 100%, 0%);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: hsla(0, 0%, 0%, 80%);
    color: white;
  }

  ${media.lg`
    font-size: ${p => p.theme.f6};
  `}
`

///////////////////////////////////////////////////////////////////////////////////

function Videos({ visibleVideos, limited, previousLimit }) {
  return (
    <List>
      {visibleVideos.map((item, i) => (
        <LiWithCSSAnimation
          key={item.embedUrl}
          style={{
            animationDelay: limited
              ? `calc(${i * 100}ms)`
              : `calc(${previousLimit * -100}ms + ${i * 100}ms)`
          }}
        >
          <EmbedWrapper>
            <Embed
              src={item.embedUrl}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            />
          </EmbedWrapper>
        </LiWithCSSAnimation>
      ))}
    </List>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const EmbedWrapper = styled.div`
  margin-top: ${p => p.theme.s5};
  ${aspectRatioParent}
  ${ratio16x9}
`

const Embed = styled.iframe`
  border: none;
  ${aspectRatioChild}
`

///////////////////////////////////////////////////////////////////////////////////

import React, { useMemo } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import '../styles/plugins/react-image-lightbox.css'

import { SrText } from './elements'

import useMachine from '../logic/useMachine'
import {
  filterAndLimitMachine,
  useRecalculateLimit,
  filterItemsByCategory,
  limitItems
} from '../logic/filterAndLimit'
import { lightboxMachine } from '../logic/lightbox'

import {
  aspectRatioParent,
  aspectRatioChild,
  container,
  enter,
  media,
  ratio16x9,
  underlineThatGrowsOnHover
} from '../styles'

export default PortfolioMedia
