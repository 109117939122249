/**
 * This template lays out the individual portfolio pages that are generated
 * programatically by gatsby-node.js from the data in portfolio.yml.
 */

function PortfolioPage({ data }) {
  const production = data.allPortfolioYaml.edges[0].node
  const { title, siteUrl } = useSiteMetadata()

  return (
    <Base>
      <Metadata
        page={{
          title: `${production.title.text.replace(`&nbsp;`, ` `)} | ${title}`,
          description: production.description,
          url: `${siteUrl}/${production.slug}`
        }}
      />

      <Hero production={production} />

      <main id="main-content">
        <Details production={production} />
        {production.media && (
          <Media
            media={production.media}
            titleForAltText={production.title.text.replace(`&nbsp;`, ` `)}
          />
        )}
        {production.press && <Press press={production.press} />}
        <Contact />
      </main>

      <Bottom />
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

export const query = graphql`
  query($slug: String!) {
    allPortfolioYaml(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title {
            text
            lang
          }
          slug
          featuredImage {
            file {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }
          company
          venue
          description
          dates
          creativeTeam {
            name
            role
          }
          performers {
            name
            role
          }
          press {
            text
            source {
              text
              href
            }
          }
          media {
            category
            embedUrl
            file {
              childImageSharp {
                thumbnail: fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                lightbox: fixed(width: 1500) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            alt
            objPosition
            caption
          }
        }
      }
    }
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import { graphql } from 'gatsby'

import Base from './Base'
import Metadata from './Metadata'
import Hero from './PortfolioHero'
import Details from './PortfolioDetails'
import Media from './PortfolioMedia'
import Press from './PortfolioPress'
import Contact from './Contact'
import Bottom from './Bottom'
import useSiteMetadata from '../data/useSiteMetadata'

export default PortfolioPage
