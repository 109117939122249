function Press({ press }) {
  return (
    <Section>
      <Heading>Press &amp; Reviews</Heading>

      <List>
        {press.map(item => (
          <Item key={item.text}>
            <Blockquote cite={item.source.href}>
              <Circle />
              <div>
                <Quote>{item.text}</Quote>
                <Source>
                  <ReadMore href={item.source.href}>{item.source.text}</ReadMore>
                </Source>
              </div>
            </Blockquote>
          </Item>
        ))}
      </List>
    </Section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Section = styled.section`
  ${container}
  padding-top: ${p => p.theme.s7};
  padding-bottom: ${p => p.theme.s8};
`

const Heading = styled.h2`
  padding-right: ${p => p.theme.s2};
  font-family: ${p => p.theme.headingFont};
  font-size: ${p => p.theme.f7};
  text-transform: uppercase;
`

const List = styled.ul`
  ${media.sm`
    margin-left: ${p => p.theme.s4};
  `}
`

const Item = styled.li`
  margin-top: ${p => p.theme.s7};
`

const Blockquote = styled.blockquote`
  display: flex;
`

const Quote = styled.p`
  max-width: 55ch;
  line-height: 1.5;
  font-size: ${p => p.theme.f4};
`

const Source = styled.cite`
  display: block;
  margin-top: ${p => p.theme.s2};
  font-style: normal;
`

const ReadMore = styled(Link)`
  ${underlineThatGrowsOnHover};
  font-family: ${p => p.theme.headingFont};

  font-weight: ${p => p.theme.fw2};
  text-transform: uppercase;
`

const Circle = styled(CircleSVG)`
  flex: none;
  margin-top: ${p => p.theme.s2};
  margin-right: ${p => p.theme.s2};
  height: 0.6em;
  width: 0.6em;
  color: ${p => p.theme.orange};
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { Link } from './elements'
import { ReactComponent as CircleSVG } from '../svg/circle-solid.svg'
import { container, media, underlineThatGrowsOnHover } from '../styles'

export default Press
